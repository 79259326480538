
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

:root {
  --spring-easing: linear(0, 0.402 7.4%, 0.711 15.3%, 0.929 23.7%, 1.008 28.2%, 1.067 33%, 1.099 36.9%, 1.12 41%, 1.13 45.4%, 1.13 50.1%, 1.111 58.5%, 1.019 83.2%, 1.004 91.3%, 1);
  --spring-duration: 400ms;
}

// Global styles
html,
body {
  @include hz-textStyle(body-regular);
  color: hz-color(text-primary);
}

.Page-title {
  @include hz-breakpoint-up(l) {
    text-shadow: 1px 1px 1px hz-color(background-default--inverse);
  }
}

.search-content {
  padding-top: hz-spacing(xs);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    'title title title title'
    'main main main main'
    'carousels carousels carousels carousels'
    'sell sell sell sell'
    'banner banner banner banner'
    'feed feed feed feed'
    'content content content content';

  @include hz-breakpoint-up(m) {
    padding: hz-spacing(xs);
  }

  @include hz-breakpoint-up(l) {
    position: relative;
    padding: 132px 0;
    display: grid;
    grid-column-gap: hz-spacing(m);
    grid-template-areas:
      'title title title title'
      'main main main sell'
      'carousels carousels carousels carousels'
      'banner banner banner banner'
      'feed feed feed feed'
      'content content content content';
  }
}

.evPage .searchSection {
  @include hz-breakpoint-up(l) {
    margin-bottom: hz-spacing(xl);
  }
}

.evPage .search-content {
  grid-template-areas:
    'title title title title'
    'main main main main'
    'content content content content'
    'faq faq faq faq'
    'partners partners partners partners';

  @include hz-breakpoint-up(l) {
    padding: 210px 0 hz-spacing(xl);
    grid-template-areas:
      'title title title title'
      'main main main main'
      'content content content content'
      'faq faq faq faq'
      'partners partners partners partners';
  }
}

.evPage .CarsSimpleSearch-SubmitButtonContainer {
  @include hz-breakpoint-up(m) {
    flex: 0 0 25%;
  }
}

.evPage .popular-brands {
  .ContentArticles-item {
    text-align: center;
  }

  .ContentArticles-itemImage {
    margin: hz-spacing(xs);
    height: 120px;
    object-fit: contain;
  }
}

input:focus,
button:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.Page {
  background: hz-color(background-light);

  &-section {
    margin-bottom: hz-spacing(l);
    padding: 0 hz-spacing(xs);
  }

  &-title {
    padding: 0 hz-spacing(xs);
    grid-area: title;

    @include hz-breakpoint-up(m) {
      padding: 0;
    }

    @include hz-breakpoint-up(l) {
      color: hz-color(text-primary--inverse);
    }
  }

  &-wrapper {
    width: 100%;
  }

  &-content {
    padding: hz-spacing(s) 0;
    max-width: 1004px;

    @include hz-breakpoint-up(m) {
      padding: hz-spacing(m) 0;
    }
  }

  &--offset {
    @include hz-breakpoint-up(m) {
      margin-top: -152px;
      position: relative;
      z-index: 1;
    }
  }
}

.Page-section--full-width {
  padding: 0;
}

.searchSection {
  max-width: 100%;
  grid-area: main;
  margin-bottom: hz-spacing(l);

  @include hz-breakpoint-up(m) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @include hz-breakpoint-up(l) {
    padding-bottom: hz-spacing(xxs);
    margin-bottom: hz-spacing(m);
  }
}

.Page-section--full-width.feedsSection {
  padding: 0 hz-spacing(xs);
}

.sellSection {
  grid-area: sell;

  @include hz-breakpoint-up(l) {
    display: flex;
    align-items: flex-end;
    padding: 0 0 hz-spacing(xxs);
    margin-bottom: hz-spacing(m);
  }
}

.carouselsSection {
  grid-area: carousels;
  margin-bottom: 0;
}

.bannerSection {
  grid-area: banner;
}

.feedsSection {
  grid-area: feed;
}

.contentSection,
.faqSection,
.partnerSection {
  margin: 0 hz-spacing(xs);

  @include hz-breakpoint-up(m) {
    margin: 0;
  }
}

.contentSection {
  grid-area: content;
}

.faqSection {
  grid-area: faq;
}

.partnerSection {
  grid-area: partners;
}

.categoryLinksSection {
  padding: 0;
}

.sectionHeader {
  margin-bottom: hz-spacing(xs);
}

.hz-Modal-Footer--mobileStretched {
  justify-content: space-between;
  box-sizing: border-box;
  min-height: 71px;

  @include hz-breakpoint-up(s) {
    min-height: 79px;
  }
}

.hz-HubControl > .hz-Icon:first-child {
  width: hz-spacing(xl);
  height: hz-spacing(xl);
}

.banner-top-dt {
  text-align: center;

  @include hz-breakpoint-up(m) {
    min-height: 90px;
  }
}

.ContentBlocks-root .hz-Card {
  height: 100%;
}

.hz-PopOverContent {
  position: absolute;
  inset: 0 auto auto 0;
  max-width: 260px;
  transform: translate(110px, 62px);
  z-index: 1;
}

.hz-PopOverContent-arrow {
  position: absolute;
  left: 50%;
}

.hz-PopOverContent-body {
  color: hz-color(text-primary--inverse);
}

.CarsSimpleSearch-FormButtons {
  display: flex;

  @include hz-breakpoint-up(m) {
    justify-content: flex-end;
  }
}

.tabs-Card {
  border-radius: 0;
  position: relative;

  @include hz-breakpoint-up(m) {
    border-radius: hz-spacing(xxxs) hz-spacing(xxxs) 0 0;
    align-self: flex-start;
  }

  .hz-Divider {
    display: none;

    @include hz-breakpoint-up(m) {
      display: block;
      line-height: 0;
      margin: 0;
    }
  }
}

.sell-Buttons {
  display: flex;

  @include hz-breakpoint-up(l) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .hz-Button {
    margin-top: hz-spacing(xxs);

    @include hz-breakpoint-up(l) {
      margin-top: 0;
      margin-right: hz-spacing(xxs);
    }
  }

  .hz-Link {
    align-items: center;
    display: flex;
    margin-right: 2%;
    min-height: 44px;
    flex: 0 0 49%;
    justify-content: center;

    @include hz-breakpoint-up(m) {
      flex-grow: 1;
    }
  }

  .hz-Button:last-child {
    margin: 0;
  }

  .place-ad-button {
    flex: 0 0 49%;

    @include hz-breakpoint-up(m) {
      flex-basis: auto;
    }
  }
}

.vehicle-Tabs {
  padding: 0 hz-spacing(xs);

  @include hz-breakpoint-up(m) {
    padding: 0 hz-spacing(s);

    .hz-TabsNext--fluid .hz-TabNext {
      flex-grow: 1;
    }
  }
}

.sell-Text {
  margin-bottom: 20px;
}

.sell-Title {
  margin-bottom: hz-spacing(xxs);
}

@keyframes carousel-slidein-xs {
  from {
    min-width: 0;
    transform: translate3d(-220px, 0, 0);
  }

  50% {
    min-width: 56%;
    transform: translate3d(-220px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes carousel-slideout-xs {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    min-width: 56%;
  }

  to {
    opacity: 0;
    min-width: 0;
  }
}

@keyframes carousel-slidein-s {
  from {
    min-width: 0;
    transform: translate3d(-220px, 0, 0);
  }

  50% {
    min-width: 38%;
    transform: translate3d(-220px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes carousel-slideout-s {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    min-width: 38%;
  }

  to {
    opacity: 0;
    min-width: 0;
  }
}

@keyframes carousel-slidein-m {
  from {
    min-width: 0;
    transform: translate3d(-220px, 0, 0);
  }

  50% {
    min-width: 22%;
    transform: translate3d(-220px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes carousel-slideout-m {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    min-width: 22%;
  }

  to {
    opacity: 0;
    min-width: 0;
  }
}

.Carousels-item {
  &.item-enter-active {
    animation-duration: 400ms;
    animation-name: carousel-slidein-xs;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    @include hz-breakpoint-up(s) {
      animation-name: carousel-slidein-s;
    }

    @include hz-breakpoint-up(m) {
      animation-name: carousel-slidein-m;
    }
  }

  &.item-exit-active {
    animation-duration: 400ms;
    animation-name: carousel-slideout-xs;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    animation-fill-mode: forwards;

    @include hz-breakpoint-up(s) {
      animation-name: carousel-slideout-s;
    }

    @include hz-breakpoint-up(m) {
      animation-name: carousel-slideout-m;
    }
  }
}

.Carousels-favButton {
  .hz-Icon {
    position: absolute;
    top: 4px;
    right: 0;
  }

  .hz-Icon--overlayDefault {
    opacity: 0.6;
  }
}

.SimpleFormFields .hz-Switch {
  padding: 8px;

  @include hz-breakpoint-up(l) {
    padding: 0;
    position: absolute;
    bottom: 24px;
    left: 32px;
    max-width: 33.33333%;

    .hz-Switch-Body {
      max-width: 62%;
      white-space: normal;
    }
  }
}

.hz-Form .hz-TextField--licensePlate {
  @include hz-breakpoint-up(l) {
    padding-bottom: hz-spacing(l);
  }
}

.faqSection .hz-Accordion-accordionItem:first-child .FaqList-questionHeading {
  border: 0;
}

.tabLink {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 200ms ease-in;
}

@keyframes jello-horizontal-1 {
  0% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(1.25, 0.75, 1);
  }
}
@keyframes jello-horizontal-2 {
  0% {
    transform: scale3d(1.25, 0.75, 1);
  }

  20% {
    transform: scale3d(0.75, 1.25, 1);
  }

  35% {
    transform: scale3d(1.15, 0.85, 1);
  }

  50% {
    transform: scale3d(0.95, 1.05, 1);
  }

  70% {
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.countLabel {
  display: inline-block;

  &.start {
    animation: jello-horizontal-1 0.1s both;
  }

  &.end {
    animation: jello-horizontal-2 0.4s both;
  }
}

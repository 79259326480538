
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.list {
  width: 100%;
}

.subListSection {
  display: flex;
  flex-wrap: wrap;

  li {
    list-style: none;
  }
}

.subList {
  flex: 1 0 33%;
  padding: hz-spacing(xs);
  margin: 0;

  @include hz-breakpoint-up(m) {
    flex: 1 0 14%;
  }
}

.l2CategoryName {
  @include hz-textStyle(body-large-strong);
}

.TabsContainer {
  display: flex;
  justify-content: center;
  border-bottom: hz-border(standard) hz-color(border-light);
  padding: 0;

  .Tabs {
    width: 100%;
    padding: 0;
  }
}

.TabPanels {
  background-color: hz-color(background-default);
}

.AllBrandsList {
  padding: hz-spacing(xs);
  margin: 0;
  columns: 7 120px;

  li {
    list-style: none;
  }
}


      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/ZIndexes';

.hz-Link.AdvancedSearchLink {
  margin-right: hz-spacing(m);
  min-height: 44px;

  .hz-Icon {
    margin-right: hz-spacing(xxxs);
  }
}

select option {
  content-visibility: hidden;
}

.hz-Form {
  padding: 0;
}

.hz-Modal-Header {
  min-height: 56px;
  border-bottom: 1px solid hz-color(border-light);
}

.hz-Modal-Footer {
  border-top: 1px solid hz-color(border-light);

  @include hz-breakpoint-up(m) {
    padding: hz-spacing(s) hz-spacing(m);
  }
}

.hz-Modal-Content {
  overflow-y: scroll;

  @include hz-breakpoint-up(m) {
    padding: hz-spacing(m);
  }

  .FormField {
    margin-bottom: hz-spacing(m);
  }

  .FormField--with-padding {
    margin-bottom: 20px;
  }

  .FormField--flex {
    display: flex;
    flex-wrap: wrap;
  }

  .FormField--flex .FormField-input {
    flex: 0 1 50%;
    box-sizing: border-box;
  }

  .FormField-input .FormField {
    margin-bottom: 0;
  }

  .FormField--flex .FormField-input:first-child {
    padding-right: hz-spacing(xxxs);

    @include hz-breakpoint-up(m) {
      padding-right: hz-spacing(xxs);
    }
  }

  .FormField--flex .FormField-input:last-child {
    padding-left: hz-spacing(xxxs);

    @include hz-breakpoint-up(m) {
      padding-left: hz-spacing(xxs);
    }
  }

  .FormField .hz-TextField {
    padding-bottom: 0;
  }
}

.FieldOptions--list {
  @include hz-textStyle(body-large);
  margin-left: hz-spacing(xxxs);

  input {
    margin: hz-spacing(xxxs) hz-spacing(xxxs) hz-spacing(xxxs) 0;
  }
}

.FormTopSection {
  padding-bottom: hz-spacing(xxs);
  border-bottom: 1px solid hz-color(border-light);
}

.FormMidSection {
  padding: hz-spacing(l) 0 hz-spacing(xxs);
  border-bottom: 1px solid hz-color(border-light);
}

.FormBottomSection {
  padding-top: hz-spacing(l);
}

.hz-TextField-formControl {
  border-color: hz-color(border-medium); //TODO: from DS
}

.hz-Modal--m {
  @include hz-breakpoint-up(s) {
    width: 100%;
    max-width: 580px;
    min-width: auto;
  }
}

.search-Card {
  margin: hz-spacing(xs) hz-spacing(xs) 0;

  @include hz-breakpoint-up(m) {
    border-radius: 0 hz-spacing(xxxs) hz-spacing(xxxs);
    margin: 0;
    position: relative;
  }
}

.SimpleFormFields .FormField {
  &:nth-child(1),
  &:nth-child(4) {
    grid-column: 1 / span 2;

    @include hz-breakpoint-up(m) {
      grid-column: auto;
    }
  }
}

.CarsSimpleSearch-SimpleFormFields.MoreFields {
  @include hz-breakpoint-up(m) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.evPage .CarsSimpleSearch-SimpleFormFields {
  @include hz-breakpoint-up(m) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.MoreFields .FormField {
  &:nth-child(1),
  &:nth-child(3) {
    grid-column: auto;
  }

  &:nth-child(3) {
    display: none;

    @include hz-breakpoint-up(s) {
      display: flex;
    }
  }
}

.evPage .FormField:first-child {
  grid-column: auto;
}

.ToggleGroup-formControl {
  margin-top: hz-spacing(xxxs);
  display: flex;
  align-items: center;

  .hz-Button,
  .hz-Button:hover, {
    @include hz-textStyle(body-large);
    flex-grow: 1;
    color: hz-color(text-primary);
    background-color: hz-color(background-default);
    border-color: hz-color(border-medium);
  }

  .hz-Button {
    @include hz-breakpoint-up(s) {
      padding: 0 hz-spacing(xxxs);
    }

    @media (hover: hover) {
      &:hover {
        background-color: hz-color(button-secondary-hover--inverse);
        border-color: hz-color(button-secondary-default);
        color: hz-color(text-primary);
      }
    }
  }

  .hz-Button:nth-child(1) {
    border-radius: hz-spacing(xxxs) 0 0 hz-spacing(xxxs);
    border-right: 0;
  }

  .hz-Button:nth-child(2) {
    border-radius: 0;
    border-right: 0;
  }

  .hz-Button:nth-child(3) {
    border-radius: 0 hz-spacing(xxxs) hz-spacing(xxxs) 0;
  }

  .hz-Button + .hz-Button.isActive,
  .hz-Button.isActive + .hz-Button {
    border-left-color: hz-color(button-secondary-default);
  }

  &:active,
  .isActive,
  .isActive:hover {
    background-color: hz-color(button-secondary-hover--inverse);
    border-color: hz-color(button-secondary-default);
    color: hz-color(text-primary);
  }
}

.FormField--toggleGroup {
  grid-column: 1 / span 2;

  @include hz-breakpoint-up(s) {
    grid-column: auto;
  }

  .FormField-Label {
    display: flex;
  }

  .ModalTrigger {
    display: inline-block;
    cursor: pointer;
    margin-left: hz-spacing(xxxs);
  }
}

.PriceTypeModal p {
  margin-bottom: hz-spacing(s);
}

.PriceTypeModal li {
  margin-bottom: hz-spacing(xs);
}


      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  width: 100%;
  text-align: left;

  @include hz-breakpoint-up(m) {
    padding: hz-spacing(m);
  }

  @include hz-breakpoint-up(l) {
    height: 100%;
  }
}

.Form {
  padding-bottom: 0;
  max-width: 360px;
}

.Text {
  @include hz-breakpoint-up(l) {
    margin-bottom: 35px;
  }
}


      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  display: none;

  @include hz-breakpoint-up(l) {
    display: block;
    position: absolute;
    margin: hz-spacing(s);
    top: 57px;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 347px;

    &.ev {
      max-height: 470px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
      border-radius: 12px;
    }
  }
}

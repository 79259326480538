
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  grid-area: continue;
}

.asCard {
  margin: hz-spacing(m) hz-spacing(xs) 0;

  @include hz-breakpoint-up(m) {
    display: none;
  }

  .searchParams {
    @include hz-textStyle(body-large);
    margin-bottom: hz-spacing(s);
  }

  .title {
    margin-bottom: hz-spacing(xs);
  }

  .container {
    padding: hz-spacing(s);
  }

  .button {
    @include hz-breakpoint-up(s) {
      max-width: 50%;
    }
  }
}

.icon {
  margin-top: 1px;
  flex: 0 0 auto;
}

.inline {
  display: none;

  @include hz-breakpoint-up(m) {
    min-width: 0;
    display: block;
    flex: 1 1 auto;
    padding: 0 hz-spacing(s) 0 hz-spacing(xxs);
    border-radius: 4px;
  }

  .title {
    margin-bottom: hz-spacing(xxxs);
  }

  .container {
    display: flex;
    align-items: center;
    max-height: 18px;
    cursor: pointer;
  }

  .searchParams {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .searchParams:hover {
    text-decoration: underline;
  }
}


      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.SimpleFormFields {
  padding: hz-spacing(s);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: hz-spacing(s);
  grid-row-gap: hz-spacing(s);

  @include hz-breakpoint-up(s) {
    padding: hz-spacing(m);
  }

  @include hz-breakpoint-up(m) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  label {
    white-space: nowrap;
  }
}

.FormButtons {
  padding: hz-spacing(s);

  @include hz-breakpoint-up(s) {
    padding: hz-spacing(s) hz-spacing(m) hz-spacing(m);
  }

  @include hz-breakpoint-up(m) {
    padding: hz-spacing(xxs) hz-spacing(s) hz-spacing(m);
  }
}

.FormButton {
  flex: 0 1 50%;

  @include hz-breakpoint-up(m) {
    flex: 0 0 auto;
  }
}

.SubmitButtonContainer {
  flex: 0 1 50%;
  padding-left: hz-spacing(xxs);

  @include hz-breakpoint-up(m) {
    flex: 0 0 33.33333%;
    padding: 0 hz-spacing(xxs);
  }
}

.AdvancedSearchLink {
  padding-right: hz-spacing(xxs);
}

.SubmitButton {
  min-width: 100%;
}

.infoTooltip {
  display: block;
  width: 30%;
}


      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

@keyframes shineLines {
  0% {
    background-position: -150px 0;
  }

  50%,
  100% {
    background-position: calc(100% + 150px) 0;
  }
}

/* stylelint-disable-next-line color-no-hex */
$base-color: #e5e5e4;
/* stylelint-disable-next-line color-no-hex */
$shine-color: #eaeaea;
$animation-duration: 1.5s;

.root {
  width: 100%;

  @include hz-breakpoint-up(m) {
    padding-left: hz-spacing(xs);
  }

  @include hz-breakpoint-up(l) {
    padding-left: 0;
  }
}

.container {
  margin-bottom: hz-spacing(m);
  position: relative;
}

.grid {
  overflow: scroll;
  display: inline-flex;
  flex-wrap: nowrap;
  overscroll-behavior: contain auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  flex: 0;
  margin: hz-spacing(xxs) 0 hz-spacing(xxs) hz-spacing(xxs);
  min-width: 56%;
  position: relative;

  @include hz-breakpoint-up(s) {
    margin-left: 1%;
    min-width: 38%;
  }

  @include hz-breakpoint-up(m) {
    margin-left: 0;
    margin-right: 1%;
    min-width: 22%;
  }

  &:first-child {
    margin-left: hz-spacing(xs);

    @include hz-breakpoint-up(m) {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-right: hz-spacing(xs);

    @include hz-breakpoint-up(m) {
      margin-right: 0;
    }
  }

  .image {
    height: 145px;
  }
}

.itemExpired article {
  opacity: 0.5;
}

.badge {
  position: absolute;
  margin: hz-spacing(xxs);
  top: 0;
  left: 0;
}

.item.small {
  text-align: center;
  min-width: 35%;

  @include hz-breakpoint-up(s) {
    min-width: 21%;
  }

  @include hz-breakpoint-up(m) {
    min-width: 14%;
  }

  .image {
    height: 105px;
  }
}

.itemTitle,
.itemSubtitle {
  overflow: hidden;
  white-space: nowrap;
}

.itemCard {
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: hz-shadow(medium);
  }
}

.title {
  margin: 0 0 hz-spacing(xxxs) hz-spacing(xs);

  @include hz-breakpoint-up(s) {
    margin-left: 1%;
  }

  @include hz-breakpoint-up(m) {
    margin-left: 0;
  }
}

.carousel {
  display: flex;
}

.view {
  overflow: scroll;
}

.image {
  object-fit: contain;
  padding: hz-spacing(xxxs);
  width: 100%;
}

.imageWithBackground {
  background-color: hz-color(background-brand-medium);
}

.skeletonImage {
  background-color: $base-color;
  background-repeat: no-repeat;
  background-size: 150px;
  content: '';
  width: 100%;
  animation: shineLines $animation-duration infinite linear;
  background-image: linear-gradient(to right, transparent 0%, $shine-color 50%, $base-color 100%);
}

.nextButton {
  margin-top: -14px;
  position: absolute;
  right: -12px;
  top: 50%;

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
}

.previousButton {
  margin-top: -14px;
  position: absolute;
  left: -12px;
  top: 50%;

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
}

.favButton {
  position: absolute;
  width: 36px;
  height: 36px;
  top: hz-spacing(xxs);
  right: hz-spacing(xxs);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent;

  &:hover,
  &:active {
    background-color: hz-color(button-tertiary-hover--inverse);
  }
}


      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  padding: 0 hz-spacing(m);
  margin-bottom: hz-spacing(xl);

  p {
    margin-bottom: hz-spacing(m);
  }
}

.heading {
  margin-bottom: hz-spacing(xs);
}

.question:first-child {
  padding-top: 0;
}

.questionHeading {
  padding: hz-spacing(m) 0;
}

.partnerLogo {
  width: 172px;
}


      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  display: none;

  @include hz-breakpoint-up(m) {
    display: block;

    .group {
      display: flex;
    }

    .contentBlock {
      padding: hz-spacing(xs);
      flex: 1 0 33.33%;
    }
  }

  .contentBlock {
    padding: hz-spacing(xs);
  }

  .title {
    margin-bottom: hz-spacing(xxs);
  }

  img {
    width: 100%;
  }

  .body {
    margin-top: hz-spacing(xxs);
    flex: 1;
  }

  .button {
    margin-top: hz-spacing(s);
  }

  .button button {
    width: 100%;
  }

  .banner {
    display: none;

    @include hz-breakpoint-up(m) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}


      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/mp/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/mp/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '../../../../../../node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  grid-area: content;
}

.articleGroup {
  margin-bottom: hz-spacing(xl);
}

.heading {
  margin-bottom: hz-spacing(xs);
}

.contentRow {
  display: flex;
  flex-wrap: wrap;
  gap: hz-spacing(s);
  align-items: stretch;

  @include hz-breakpoint-up(m) {
    flex-wrap: nowrap;
  }
}

.item {
  flex: 1 1 0;
  min-width: 45%;
  text-align: left;

  @include hz-breakpoint-up(m) {
    min-width: 15%;
  }
}

.itemCard {
  height: 100%;

  &:hover {
    box-shadow: hz-shadow(medium);
  }
}

.itemImage {
  border-radius: 4px 4px 0 0;
}

.itemTitle {
  margin-bottom: hz-spacing(xxs);
}

.itemLink {
  margin-top: hz-spacing(xxs);
}

.itemTitle2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalImage {
  width: 100%;
  min-height: 354px;
  margin-bottom: hz-spacing(s);
}

.modalTitle {
  margin-bottom: hz-spacing(m);
}

.articleModal {
  p {
    margin-bottom: hz-spacing(m);
  }
}

.partnerFooter {
  padding-top: hz-spacing(s);
  display: flex;
  gap: hz-spacing(xs);
  border-top: 1px solid hz-color(border-light);

  p {
    margin-bottom: 0;
    line-height: 21px;
    color: hz-color(text-secondary);
  }

  img {
    width: 112px;
    flex: 0 0 44px;
  }
}

.partnerCopy {
  display: flex;
  flex-direction: column;
  gap: hz-spacing(xxxs);
}
